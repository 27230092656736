import {ViewState} from "../states";
import React, {forwardRef, useEffect, useState} from "react";
import {CloseCircleTwoTone, TableOutlined} from "@ant-design/icons";
import {Skeleton, Tooltip} from "antd";
import {get} from "../core/request";
import $ from "jquery";

interface AsyncTableProps {
    uuid: String,
}


export const AsyncTable = forwardRef(
    (props: AsyncTableProps, ref) => {
        const [state, setState] = useState(ViewState.loading);
        const [content, setContent] = useState(undefined);
        if (ref) {
            // @ts-ignore
            ref.current = {content}
        }

        useEffect(() => {
            query_api();
        }, [props.uuid])

        const breakLine = (s: string) => {
            if (typeof s === 'string') {
                return s.split('\n').map((line: string) => <>{line}<br/></>)
            }

            return s

        }

        const query_api = () => {
            get("reports/table/" + props.uuid)
                .done((response, status, jqXHR) => {
                    switch (jqXHR.status) {
                        case 200:
                            console.debug("AsyncTable Loaded:", response.data)
                            setContent(response.data.table);
                            setState(ViewState.success);
                            return;
                        default:
                        case 202:
                            setTimeout(query_api, 3000, props);  // check again in 3 sec
                            return;
                    }
                })
                .fail((e) => {
                    console.error("AsyncTable Failed:", e);
                    setState(ViewState.failed);
                })
        }

        const Retry = () => {
            query_api();
        }

        switch (state) {
            case ViewState.loading:  //TODO update
                return <Skeleton.Node active><TableOutlined
                    style={{fontSize: 40, color: '#bfbfbf'}}/></Skeleton.Node>
            case ViewState.success:
                // @ts-ignore
                let head = content?.head
                // @ts-ignore
                let spacing = content?.spacing?.replaceAll('|', ';').split(';')
                if (spacing[0] === '') {
                    spacing.shift()
                }
                if (spacing[spacing.length - 1] === '') {
                    spacing.pop()
                }
                if (spacing !== undefined) {
                    for (let i in spacing) {
                        // 'centering' in spacing[i]
                        if (spacing[i].includes('centering')) {
                            spacing[i] = 'c'
                        }
                    }
                }

                // @ts-ignore
                let body = content?.body
                // @ts-ignore
                let idx = [...Array(head.length).keys()]

                // dict to map the column name to the alignment
                // @ts-ignore
                let align: [String] = []
                for (let i in idx) {
                    if (spacing === undefined || spacing[i] === undefined) {
                        align.push('left')
                    } else {
                        switch (spacing[i]) {
                            case 'r':
                                align.push('right')
                                break
                            case 'c':
                                align.push('center')
                                break
                            default:
                            case 'X':
                            case 'l':
                                align.push('left')
                                break
                        }
                    }
                }

                // @ts-ignore
                const max_width = $('#main-view')?.width() ? $('#main-view').width() - 96 : "21cm"

                return <span style={{
                    minWidth: '100%', justifyContent: 'center', display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    {
                        // @ts-ignore
                        content?.title ? <h1 style={{width: '100%'}}>{
                            // @ts-ignore
                            content.title
                        }</h1> : <></>}
                    <div style={{
                        minWidth: max_width,
                        maxWidth: max_width,
                        overflowY: 'auto',
                        overflowX: 'auto',
                    }}>
                        <table className='reportTable' style={{
                            // @ts-ignore
                            //minWidth: content?.width ? content.width : "100%",
                            overflowWrap: 'break-word',
                            margin: 'auto',
                        }}>
                            <thead>
                                <tr>
                                    {
                                        // @ts-ignore
                                        idx.map((i: any) => <th style={{textAlign: align[i]}}>{breakLine(head[i])}</th>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                // @ts-ignore
                                body.map(
                                    (row: any) => <tr>
                                        {
                                            idx.map((i: any) => <td
                                                style={{
                                                    // @ts-ignore
                                                    textAlign: align[i]
                                                }}>{breakLine(row[i])}</td>)
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </span>
            case ViewState.failed:  //TODO update
            default:
                return <div
                    onClick={Retry}
                ><Tooltip title="Die Tabelle konnte nicht geladen werden. Bitte versuchen Sie es erneut.">
                    <Skeleton.Node><CloseCircleTwoTone twoToneColor="#F5222D"
                                                       style={{fontSize: 40}}/></Skeleton.Node></Tooltip></div>

        }
    }
)