import {Breadcrumb, Button, Card, message, notification, Result, Tabs} from "antd";
import {ToolTwoTone} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import {useAppSelector} from "../store";
import {useEffect, useState} from "react";
import {Customer, Device} from "../core/datamodel";
import {ViewState} from "../states";
import {get, post} from "../core/request";
import Loading from "../components/loading";
import TextArea from "antd/es/input/TextArea";
import {CustomButton} from "../components/buttons";

export default function FilterSettingsView(props: any) {
    const [filters, setFilters] = useState({} as JSON);
    const [state, setState] = useState(ViewState.loading);


    useEffect(() => {
        get("eventfilters").done((response) => {
                setFilters(response.data.eventfilters);
                setState(ViewState.success);
            }
        ).fail(() => setState(ViewState.failed))
    }, [])

    const breadcrumb = (
        <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Event-Filter Einstellungen</Breadcrumb.Item>
        </Breadcrumb>
    )


    const tabs = state === ViewState.success ? (
        <Card title="Event Filter Konfiguration" extra={
            <CustomButton
                mode={"save"}
                // @ts-ignore
                onClick={
                    () => {
                        // @ts-ignore
                        post("eventfilters", JSON.parse(document.getElementById("filterJSON")?.value))
                            .done(message.success("Speichern abgeschlossen."))
                    }
                }
            />
        }>
            <TextArea
                id="filterJSON"
                contentEditable
                rows={25}
                defaultValue={JSON.stringify(filters, null, 4)}/>
        </Card>
    ) : (<></>)

    switch (state) {
        case ViewState.loading:
            return (
                <>
                    {breadcrumb}
                    <Loading/>
                </>
            )
        case ViewState.success:
            return (
                <>
                    {breadcrumb}
                    {tabs}
                </>
            )

        case ViewState.failed:
        default:
            return (
                <>
                    {breadcrumb}
                    <Result
                        status="500"
                        title="Etwas hat nicht funktioniert"
                        subTitle="Entschuldigung, es ist ein unerwarteter Fehler aufgetreten!"
                    />
                </>
            )
    }
}
