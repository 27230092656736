import {Button, FormInstance, Modal, Upload} from "antd";
import {useEffect, useState} from "react";
import {CustomButton} from "./buttons";
import Dragger from "antd/es/upload/Dragger";
import {IconSet} from "./icons";

var vCard = require('vcf')

interface VCFImportProps {
    form: FormInstance
}


export function VCFImport(props: VCFImportProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const handleCancel = () => {
        setModalOpen(false);
    }

    //@ts-ignore
    const handleUpload = (e) => {
        e.file.text().then((data: string) => {
            let card = new vCard().parse(data.replaceAll("\r", "\n").replaceAll("\n", "\r\n"))
            let values = props.form.getFieldsValue()

            let n = card.get("n")._data.split(";")
            values.surname = n[0];
            values.firstname = n[1];
            values.title = n[3];
            values.phone = card.get("tel")._data? card.get("tel")._data: card.get("tel")[0]._data;
            values.mail = card.get("email")._data ? card.get("email")._data : card.get("email")[0]._data;
            values.comment = (values.comment ? values.comment + "\n" : "") + card.get("title")._data + "\n" + card.get("org")._data

            props.form.setFieldsValue(values);
            Modal.destroyAll();
        })
    }

    const showModal = () => {
        Modal.info({
                title: "vCard hochladen",
                okText: "Abbrechen",
                okType: "ghost",
                closable: true,
                maskClosable: true,
                icon: undefined,
                content: <Dragger
                    {...props}
                    customRequest={handleUpload}
                >
                    <p className="ant-upload-text">Klicken oder Datei hier ablegen</p>
                    <p className="ant-upload-hint">
                        vCards im .vcf-Format werden unterstützt
                    </p>
                </Dragger>
            }
        )
        return true;
    }

    return (
        <>
            <CustomButton
                mode="vCard"
                tooltip="vCard hochladen"
                onClick={showModal}
            />
        </>
    )
}
