import {ViewState} from "../states";
import {useEffect, useState} from "react";
import {DotChartOutlined, CloseCircleTwoTone} from "@ant-design/icons";
import {Image, Skeleton, Slider, Tooltip} from "antd";
import {get} from "../core/request";

interface AsyncImageProps {
    uuid: String,
    style?: any,
}

export const AsyncImage = (props: AsyncImageProps) => {
    const [state, setState] = useState(ViewState.loading);
    const [image, setImage] = useState(undefined);

    useEffect(() => {
        query_api();
    }, [props.uuid])

    const query_api = () => {
        get("reports/image/" + props.uuid)
            .done((response, status, jqXHR) => {
                switch (jqXHR.status) {
                    case 200:
                        setImage(response.data.image);
                        console.debug("AsyncImage Loaded:", response.data)
                        setState(ViewState.success);
                        return;
                    default:
                    case 202:
                        setTimeout(query_api, 3000, props);  // check again in 3 sec
                        return;
                }
            })
            .fail((e)=> {
                console.error("AsyncImage Failed:",e);
                setState(ViewState.failed);
            })
    }

    switch (state) {
        case ViewState.loading:
            return <Skeleton.Node active><DotChartOutlined style={{fontSize: 40, color: '#bfbfbf'}}/></Skeleton.Node>
        case ViewState.success:
            return <Image src={image} {...props}/>
        case ViewState.failed:
        default:
            return <Tooltip title="Das Bild konnte nicht geladen werden. Bitte versuchen Sie es erneut.">
                <Skeleton.Node><CloseCircleTwoTone twoToneColor="#F5222D" style={{fontSize: 40}}/></Skeleton.Node>
            </Tooltip>

    }
}