import {Button, DatePicker, Form, InputNumber, Result, Space, Spin, Switch} from "antd";
import {IconSet} from "./icons";
import React, {useEffect, useState} from "react";
import {get, post} from "../core/request";
import {ViewState} from "../states";
import {Device, ManualEntry} from "../core/datamodel";
import {closeDrawer} from "../reducers/drawers-reducer";
import {useAppDispatch} from "../store";
import 'dayjs/locale/de';
import {triggerReload} from "../reducers/reloadsignal-reducer";
import dayjs from "dayjs";

interface ManualEntryFormProps {
    deviceId: string | undefined
}

export default function ManualEntryForm(props: ManualEntryFormProps) {
    const [device, setDevice] = useState({} as Device);
    const [state, setState] = useState(ViewState.loading);
    const [mode, setMode] = useState(true);
    const manualEntry = new ManualEntry({});
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const deviceType = device?.props?.device_model?.props?.type ? device?.props?.device_model?.props?.type : ""

    const unit = {
        undefined: "",
        "": "",
        "CT": "Serien",
        "MG": "Aufnahmen",
        "NM": "Aufnahmen",
        "PET/CT": "Untersuchungen",
        "RF": "Untersuchungen",
        "XR": "Aufnahmen",
    }[deviceType]

    useEffect(() => {
        setState(ViewState.loading);
        get("devices/" + props.deviceId).then(
            (response) => {
                setDevice(new Device(response.data.device));
                manualEntry.props.month = dayjs().subtract(1, 'month')
                manualEntry.props.year = dayjs().subtract(1, 'year')
                form.resetFields()
                form.setFieldsValue(manualEntry?.props)
                setState(ViewState.success);
            },
            (e) => {
                setState(ViewState.failed);
            })
    }, [props.deviceId, form])

    const onFormFinish = () => {
        let data = form.getFieldsValue()
        if (mode) {
            data.year = undefined;
        } else {
            data.month = undefined;
        }
        manualEntry.update(data)
        manualEntry.props.device = device;
        let req: JQueryPromise<any> = post("manualentrys", manualEntry.dump())
        req.then(() => {
                dispatch(triggerReload('manualEntry'))
                dispatch(closeDrawer('upload'));
            },
            (e) => {
                console.error(e);
            }
        )
    }

    //todo add required logic
    const deviceFormElements = () => {
        switch (deviceType) {
            case "CT":
                return (<>
                    <Form.Item
                        label={unit + " mit Überschreitung"}
                        name="n_exc"
                        //rules={[{required: true, message: 'Wert benötigt!'}]}
                        required
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"CTDI Überschreitungen"}
                        name="n_ctdi_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"CTDI Überschreitungen >200%"}
                        name="n_ctdi_exc_gt200"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. CTDI"}
                        name="ctdi_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. CTDI-Überschreitung"}
                        name="ctdi_exc_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"DLP Überschreitungen"}
                        name="n_dlp_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"DLP Überschreitungen >200%"}
                        name="n_dlp_exc_gt200"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. DLP"}
                        name="dlp_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. DLP_Überschreitung"}
                        name="dlp_exc_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={unit + " mit Body-Phantom"}
                        name="n_ctdi_thorax"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={unit + " mit Body-Phantom >80 mGy"}
                        name="n_ctdi_thorax_gt80"
                        tooltip={"Für diesen Wert muss auch '" + unit + " des Thorax" + "' gefüllt werden"}

                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. CTDI mit Body-Phantom"}
                        name="ctdi_thorax_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={unit + " des Schädel-Phantom"}
                        name="n_ctdi_head"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={unit + " des Schädel-Phantom > 120 mGy"}
                        name="n_ctdi_head_gt120"
                        tooltip={"Für diesen Wert muss auch '" + unit + " fes Schädel" + "' gefüllt werden"}

                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. CTDI des Schädel-Phantom"}
                        name="ctdi_head_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>
                </>)

            case "PET/CT":
                return (<>
                    <Form.Item
                        label={unit + " mit Überschreitung"}
                        name="n_exc"
                        //rules={[{required: true, message: 'Wert benötigt!'}]}
                        required
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Aktivitätsberschreitungen"}
                        name="n_inj_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"CTDI Überschreitungen"}
                        name="n_ctdi_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. Aktivität"}
                        name="inj_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. Aktivitätsberschreitung"}
                        name="inj_exc_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"CTDI Überschreitungen >200%"}
                        name="n_ctdi_exc_gt200"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. CTDI"}
                        name="ctdi_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. CTDI-Überschreitung"}
                        name="ctdi_exc_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                </>)

            case "NM":
                return (<>
                    <Form.Item
                        label={"Aktivitätsberschreitungen"}
                        name="n_inj_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. Aktivität"}
                        name="inj_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. Aktivitätsberschreitung"}
                        name="inj_exc_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>
                </>)

            case "MG":
                return (<>
                    <Form.Item
                        label={"AGD Überschreitungen"}
                        name="n_agd_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. AGD"}
                        name="agd_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. AGD-Überschreitung"}
                        name="agd_exc_max"
                    >
                        <InputNumber decimalSeparator={","} placeholder="Wert"/>
                    </Form.Item>
                </>)

            case "RF":
                return (<>
                    <Form.Item
                        label={"Acc. DFP Überschreitungen"}
                        name="n_dfp_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Acc. DFP Überschreitungen >200%"}
                        name="n_dfp_exc_gt200"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. Acc. DFP"}
                        name="dfp_max"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. Acc. DFP-Überschreitung"}
                        name="dfp_exc_max"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Anzahl DFP > 20000"}
                        name="n_dfp_gt20000"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Anzahl DFP > 50000"}
                        name="n_dfp_gt50000"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>
                </>)
            case "XR":
                return (<>
                    <Form.Item
                        label={"DFP Überschreitungen"}
                        name="n_dfp_exc"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"DFP Überschreitungen >200%"}
                        name="n_dfp_exc_gt200"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. DFP"}
                        name="dfp_max"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"Max. DFP-Überschreitung"}
                        name="dfp_exc_max"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>
                </>)

            default:
                return ""
        }
    }

    const chlfFormElements = () => {
        switch (deviceType) {
            case "CT":
                return (<>
                    <Form.Item
                        label={"falsche Kindzuordnungen"}
                        name="n_child_clf_incor"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>

                    <Form.Item
                        label={"falsche Phantomzuordnungen"}
                        name="n_phantom_clf_incor"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>
                </>)

            case "NM":
            case "PET":
            case "RF":
            case "XR":
                return (<>
                    <Form.Item
                        label={"falsche Kindzuordnungen"}
                        name="n_child_clf_incor"
                    >
                        <InputNumber placeholder="Anzahl"/>
                    </Form.Item>
                </>)

            case "MG":
            default:
                return ""
        }
    }


    const form_element = (
        <Form
            form={form}
            onFinish={onFormFinish}
            labelCol={{span: 12}}
            wrapperCol={{span: 12}}
        >
            <div style={{textAlign: 'center', width: '100%'}}>
                <h2>{device?.props?.name}</h2>
            </div>
            <Form.Item
                label=" "
                colon={false}
                name="mode"
            >
                <Switch
                    checkedChildren={"Monat"}
                    unCheckedChildren={"Jahr"}
                    style={{minWidth: 75}}
                    defaultChecked={mode}
                    size={'default'}
                    onChange={(checked) => setMode(checked)}
                />
            </Form.Item>
            {
                mode ?
                    /*<Form.Item
                        label="Monat"
                        name='month'
                        rules={[{required: true, message: 'Wert benötigt!'}]}
                        required
                    >
                        <DatePicker
                            picker="month"
                            placement='bottomLeft'
                            format={"MMMM YYYY"}
                            style={{width: 255}}
                            popupStyle={{zIndex: 2000}}
                        />
                    </Form.Item>*/
                    <span>Bitte den Workflow zur Erzeugung von Berichten für die eingabe manueller Daten nutzen. </span>
                    :
                    <>
                        <Form.Item
                            label="Jahr"
                            name='year'
                            rules={[{required: true, message: 'Wert benötigt!'}]}
                            required
                        >
                            <DatePicker
                                picker="year"
                                placement='bottomLeft'
                                format={"YYYY"}
                                style={{width: 255}}
                                popupStyle={{zIndex: 2000}}
                            />
                        </Form.Item>
                        <Form.Item
                            label={unit}
                            name="n_events"
                            //rules={[{required: true, message: 'Wert benötigt!'}]}
                            required
                        >
                            <InputNumber placeholder="Anzahl"/>
                        </Form.Item>

                        <Form.Item
                            label={"relevante " + unit}
                            name="n_relRef"
                            //rules={[{required: true, message: 'Wert benötigt!'}]}
                            required
                        >
                            <InputNumber placeholder="Anzahl"/>
                        </Form.Item>

                        {deviceFormElements()}

                        <Form.Item
                            label={unit + " mit BMI-Angabe"}
                            name="n_pat_bmi"
                        >
                            <InputNumber placeholder="Anzahl"/>
                        </Form.Item>
                        {chlfFormElements()}
                        <Form.Item
                            label={unit + " ohne Referenzwert obwohl nötig"}
                            name="n_noRef"
                            tooltip={"Diese Anzahl zählt " + unit + " ohne Referenzwert obwohl dieser laut Protokoll nötig gewesen wäre"}
                        >
                            <InputNumber placeholder="Anzahl"/>
                        </Form.Item>

                        <Form.Item
                            label={"kommentierte Überschreitungen"}
                            name="n_comment_exc"
                        >
                            <InputNumber placeholder="Anzahl"/>
                        </Form.Item>


                        {/* Buttons */}
                        <Form.Item label=" " colon={false}>
                            <Space style={{width: 512, alignSelf: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    <IconSet type='save'/>&nbsp;Speichern
                                </Button>
                                <Button danger onClick={() => form.setFieldsValue(manualEntry?.props)}>
                                    <IconSet type='cancel'/>&nbsp;Zurücksetzen
                                </Button>
                            </Space>
                        </Form.Item>
                    </>
            }
        </Form>
    )

    switch (state) {
        default:
        case ViewState.loading:
            return (<Result icon={<Spin spinning/>} title={"Upload wird vorbereitet..."}/>)
        case ViewState.failed:
            return (<Result status={500} title={"Etwas hat nicht funktioniert."}
                            subTitle={"Der Upload konnte nicht vorbereitet werden."}/>)
        case ViewState.success:
            return form_element
    }
}