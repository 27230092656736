import {Breadcrumb, Button, Card, Form, Modal, Result, Select, Tooltip} from "antd";
import {Link, useParams} from "react-router-dom";
import {Customer, Device} from "../core/datamodel";
import {del, get, post} from "../core/request";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store";
import {ViewState} from "../states";
import Loading from "../components/loading";
import {ApiTable} from "../components/table";
import {IconSet} from "../components/icons";
import {triggerReload} from "../reducers/reloadsignal-reducer";
import CustomSelect from "../components/select";

export default function DeviceColumnMappingView(props: any) {
    const params: { id: string } = useParams();
    const reloadSignal = useAppSelector(state => state.reloadSignals.device)
    const [device, setDevice] = useState({} as Device);
    const [state, setState] = useState(ViewState.loading);
    const [headerState, setHeaderState] = useState(ViewState.loading);
    const [headerData, setHeaderData] = useState({} as any);
    const [permissions, setPermissions] = useState({} as any);
    const [form] = Form.useForm();

    const [customer, setCustomer] = useState({} as Customer);
    const customerReloadSignal = useAppSelector(state => state.reloadSignals.customer)


    useEffect(() => {
        get("devices/" + params.id).done((response) => {
                setDevice(new Device(response.data.device));
                setState(ViewState.success);
            }
        ).fail(() => setState(ViewState.failed))

        get("devices/" + params.id + "/columnmappings/options").done((response) => {
                setHeaderData(response.data);
                setHeaderState(ViewState.success);
            }
        ).fail(() => setHeaderState(ViewState.failed))

    }, [params.id, reloadSignal])

    useEffect(() => {
        get("devices/" + params.id + "/permissions").done((response) => {
                setPermissions(response.data.permissions);
            }
        )
    }, [params.id, reloadSignal])

    useEffect(() => {
        if (!!device?.props?.customer?.props?.uuid) {
            get("customers/" + device.props.customer.props.uuid).done(
                (response) => {
                    setCustomer(new Customer(response.data.customer));
                }
            )
        }
    }, [device?.props?.customer, customerReloadSignal])

    let dispatch = useAppDispatch();


    const breadcrumb = (
        <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/customers">Kunden</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link
                to={"/customers/" + customer.props?.uuid}>{!!customer.props?.repr ? customer.props.repr : "Unknown"}</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link
                to={"/devices/" + params.id}>{!!device?.props?.name ? device.props.name : "Unknown"}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Spalten-Zuordnung</Breadcrumb.Item>
        </Breadcrumb>
    )

    const addmapping = (values: any) => {
        post("columnmappings", {
            device: {uuid: device.props.uuid},
            source: values.quelle,
            target: values.ziel
        }).then(() => {
            form.resetFields()
        }).fail((resp:any) => {
            Modal.error({title: "Fehler", content: resp?.responseJSON?.data?.description? resp.responseJSON.data.description : "Die Spalten-Zuordnung konnte nicht hinzugefügt werden."})
        })
    }

    const pageHeader = (
        <Card
            title={device?.props?.name ? <>Neue manuelle Zuordnung für {device.props.name }</> : "Neue Zuordnung"}
        >
            {
                headerState === ViewState.success ?
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={addmapping}
                        labelCol={{span: 4, offset: 5}}
                        wrapperCol={{span: 6}}
                    >
                        <Form.Item label="Quelle" name="quelle" rules={[{required: true}]}>
                            <CustomSelect searchable >
                                {headerData.sources ? headerData.sources.map((source: any) => (
                                    <Select.Option key={source} value={source}>{source}</Select.Option>
                                )) : <></>}
                            </CustomSelect>
                        </Form.Item>
                        <Form.Item label="Ziel" name="ziel" rules={[{required: true}]}>
                            <CustomSelect searchable >
                                {headerData.targets ? Object.keys(headerData.targets).map(target => (
                                    <Select.Option
                                        key={target}
                                        value={target}
                                    >
                                        {headerData.targets[target]}
                                    </Select.Option>
                                )) : <></>}
                            </CustomSelect>
                        </Form.Item>
                        <Form.Item wrapperCol={{offset: 9, span: 6}}>
                            <Button type="primary" htmlType="submit" style={{float: "right"}}>Hinzufügen</Button>
                        </Form.Item>
                    </Form> : <>
                        {headerState === ViewState.loading ? <Loading/> : <>Sie haben keine Berechtigung dieses Mapping zu
                            bearbeiten. Bitte wenden Sie sich an einen Administrator.</>}
                    </>
            }
        </Card>
    )

    const pageBody = (
        <ApiTable
            title={<>aktuelle Spalten-Zuordung</>}
            id="Device_ColumnMapping"
            url={"devices/" + device?.props?.uuid + "/columnmappings"}
            reloadSignals={[useAppSelector(state => state.reloadSignals.columnMapping)]}
            searchable
            heightReducer={296 + 273 + 24}
            actions={(text: any, row: any) => (
                // roww key starts with "Default_" are not deletable
                row?.key && !row.key.startsWith("Default_") && !row.key.startsWith("Unused_") ?
                    <Tooltip overlay={"Zuordnung löschen"}>
                        <IconSet
                            type='delete'
                            //@ts-ignore
                            className="action-delete"
                            //@ts-ignore
                            onClick={() => {
                                Modal.confirm({
                                    title: "Zuordnung löschen",
                                    content: "Soll die Zuordnung '" + row.quelle + "' -> '" + row.ziel + "' wirklich gelöscht werden?",
                                    maskClosable: true,
                                    cancelText: 'Abbrechen',
                                    okText: 'Löschen',
                                    okType: 'danger',
                                    autoFocusButton: 'cancel',
                                    onOk: () => {
                                        del("columnmappings/" + row.key).then(() => {
                                                dispatch(triggerReload('columnMapping'))
                                            }
                                        )
                                    }
                                });

                                return true;
                            }}
                        />
                    </Tooltip> : <></>
            )}
        />
    )

    switch (state) {
        case ViewState.loading:
            return (
                <>
                    {breadcrumb}
                    {pageHeader}
                    <Loading/>
                </>
            )
        case ViewState.success:
            return (
                <>
                    {breadcrumb}
                    {pageHeader}
                    <br/>
                    {pageBody}
                </>
            )

        case ViewState.failed:
        default:
            return (
                <>
                    {breadcrumb}
                    <Result
                        status="500"
                        title="Es ist ein Fehler aufgetreten."
                        subTitle="Bitte versuchen Sie es später erneut."
                    />
                </>
            )
    }
}