import React, {forwardRef, useCallback, useMemo, useState} from 'react'
import isHotkey from 'is-hotkey'
import {Editable, withReact, useSlate, Slate} from 'slate-react'
import {Editor, Transforms, createEditor, Descendant, Element as SlateElement, BaseEditor} from 'slate'
import {withHistory} from 'slate-history'
import {Button, Card, Popover, Space, Tooltip} from 'antd';
import {CirclePicker} from 'react-color';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faHeading,
    faFillDrip,
    faAlignLeft,
    faAlignRight,
    faAlignCenter,
    faListOl,
    faListUl,
    faItalic,
    faBold,
    faUnderline,
    faFileCirclePlus
} from "@fortawesome/free-solid-svg-icons";

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
}

const LIST_TYPES: Array<String> = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES: Array<String> = ['left', 'center', 'right']
export const DEFAULT_COLORS = ['#000000', '#FF6900', '#FCB900', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#9900EF']
const DEFAULT_CONTENT: Descendant[] = [{
    type: 'paragraph',
    align: 'left',
    children: [{text: ''}]
} as Descendant]
export const DEMO_CONTENT: Descendant[] = [
    {
        type: 'paragraph',
        align: 'left',
        children: [
            {text: 'This is editable '},
            // @ts-ignore
            {text: 'rich', bold: true},
            {text: ' text, '},
            // @ts-ignore
            {text: 'much', italic: true},
            {text: ' better than a '},
            // @ts-ignore
            {text: 'textarea', color: "#ff0000"},
            {text: '!'},
        ],
    },
    {
        type: 'paragraph',
        align: 'left',
        children: [
            {
                text:
                    "Since it's rich text, you can do things like turn a selection of text ",
            },
            // @ts-ignore
            {text: 'bold', bold: true},
            {
                text:
                    ', or add a semantically rendered block quote in the middle of the page, like this:',
            },
        ],
    },
    {// @ts-ignore
        type: 'paragraph',
        align: 'center',
        children: [{text: 'Try it out for yourself!'}],
    },
]

interface RichTextEditorProps {
    content?: Descendant[],
    colors?: string[],
    placeholder?: string,
    disableFormating?: boolean
}

const RichTextEditor = forwardRef(
    (props: RichTextEditorProps, ref) => {
        const renderElement = useCallback((props: JSX.IntrinsicAttributes & { attributes: any; children: any; element: any }) =>
            <Element {...props} />, [])
        const renderLeaf = useCallback((props: JSX.IntrinsicAttributes & { attributes: any; children: any; leaf: any }) =>
            <Leaf {...props} />, [])
        const editor = useMemo(() => withHistory(withReact(createEditor())), [])

        const initialValue = props.content ? props.content : DEFAULT_CONTENT
        const [value, setValue] = useState(initialValue);

        const placeholder = props.placeholder ? props.placeholder : "Text eingeben ... "

        if (ref) {
            // @ts-ignore
            ref.current = {value}
        }

        return (
            <Slate editor={editor} value={initialValue} onChange={(value) => setValue(value)}>
                <Card
                    type="inner"
                    title={
                        !!props.disableFormating ? "" : (
                            <Space>
                                <MarkButton format="bold" icon={faBold}/>
                                <MarkButton format="italic" icon={faItalic}/>
                                <MarkButton format="underline" icon={faUnderline}/>
                                <br/>|<br/>
                                <BlockButton format="heading" icon={faHeading}/>
                                <BlockButton format="numbered-list" icon={faListOl}/>
                                <BlockButton format="bulleted-list" icon={faListUl}/>
                                <BlockButton format="pagebreak" icon={faFileCirclePlus}/>
                                <br/>|<br/>
                                <BlockButton format="left" icon={faAlignLeft}/>
                                <BlockButton format="center" icon={faAlignCenter}/>
                                <BlockButton format="right" icon={faAlignRight}/>
                                <br/>|<br/>
                                <ColorPicker colors={props.colors}/>
                            </Space>
                        )
                    }
                >
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={placeholder}
                        spellCheck
                        autoFocus
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event as any)) {
                                    event.preventDefault()
                                    // @ts-ignore
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                        }}
                    />
                </Card>
            </Slate>)
    }
)

const toggleBlock = (editor: BaseEditor, format: String) => {
    console.debug(editor, format);
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            // @ts-ignore
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            // @ts-ignore
            align: isActive ? 'left' : format,
        }
    } else {
        newProperties = {
            // @ts-ignore
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
        // align in list is always left
        if (isList) {
            newProperties = {
                ...newProperties,
                // @ts-ignore
                align: 'left',
            }
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = {type: format, children: []}
        Transforms.wrapNodes(editor, block)
    }
}

const isBlockActive = (editor: BaseEditor, format: String, blockType = 'type') => {
    const {selection} = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                // @ts-ignore
                n[blockType] === format,
        })
    )

    return !!match
}

// @ts-ignore
const BlockButton = ({format, icon}) => {
    const editor = useSlate()
    // disable alignment in lists
    const disabled = () => (
        ["left", "center", "right"].includes(format) &&
        (
            isBlockActive(editor, "numbered-list") ||
            isBlockActive(editor, "bulleted-list") ||
            isBlockActive(editor, "pagebreak")
        )
    )
    return (
        <Tooltip
            title={disabled() ? "in Listen nicht verfügbar" : format==="pagebreak" ? "Seitenumbruch" : ""}
        >
            <Button
                shape='circle'
                type={isBlockActive(
                    editor,
                    format,
                    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
                ) ? 'primary' : 'default'}
                onMouseDown={(event: { preventDefault: () => void }) => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
                icon={<FontAwesomeIcon icon={icon}/>}
                disabled={disabled()}
            >
            </Button>
        </Tooltip>
    )
}

const toggleMark = (editor: BaseEditor, format: string) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isMarkActive = (editor: BaseEditor, format: string): boolean => {
    const marks = Editor.marks(editor)
    // @ts-ignore
    return marks ? marks[format] === true : false
}

const MarkButton = ({format, icon}: any) => {
    const editor = useSlate()
    const disabled = () => isBlockActive(editor, "pagebreak")
    return (
        <Button
            shape='circle'
            type={isMarkActive(editor, format) ? 'primary' : 'default'}
            onMouseDown={(event: { preventDefault: () => void }) => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
            icon={<FontAwesomeIcon icon={icon}/>}
            disabled={disabled()}
        >
        </Button>
    )
}

const getColor = (editor: BaseEditor) => {
    const marks = Editor.marks(editor)
    // @ts-ignore
    return marks ? marks["color"] : "#000000"
}

const setColor = (editor: BaseEditor, color: string) => {
    if (color == "#000000") {
        Editor.removeMark(editor, "color")
    } else {
        Editor.addMark(editor, "color", color)
    }
}

const ColorPicker = (props: { colors?: string[] }) => {
    const editor = useSlate()
    const disabled = () => isBlockActive(editor, "pagebreak")
    const availableColors = props.colors ? props.colors : DEFAULT_COLORS
    return (
        <Popover placement="left" content={
            <CirclePicker
                onChangeComplete={(d) => {
                    setColor(editor, d.hex)
                }}
                colors={availableColors}
            />} trigger="hover">
            <Button
                shape='circle'
                disabled={disabled()}
            ><FontAwesomeIcon
                // @ts-ignore
                icon={faFillDrip} color={getColor(editor)}/></Button>
        </Popover>
    )
}

// @ts-ignore
const Element = ({attributes, children, element}) => {
    const style = {textAlign: element.align}
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        case 'pagebreak':
            return (
                <p style={{color: 'gray'}}> --- Neue Seite ---
                    {children}
                </p>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

// @ts-ignore
const Leaf = ({attributes, children, leaf}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }
    if (leaf.color!) {
        children = <span style={{color: leaf.color}}>{children}</span>
    }

    return <span {...attributes}>{children}</span>
}


export default RichTextEditor;