import React, {useEffect, useState} from "react";
import {ViewState} from "../states";
import {Spin} from "antd";
import {checkForNotifications, get} from "../core/request";
import {APIHost} from "../config";

interface PdfPreviewProps {
    uuid: string
}

export const PdfPreview = (props: PdfPreviewProps) => {
    const [state, setState] = useState(ViewState.loading);
    const [url, setUrl] = useState("");
    useEffect(() => {
        setTimeout(monitor, 0, props);
    }, [props.uuid])

    const monitor = () => {
        get("download/" + props.uuid + "/status").done(
            (data, status, jqXHR) => {
                let desc = "Verarbeitung abgeschlossen."
                switch (jqXHR.status) {
                    case 202:
                        // still working
                        setTimeout(monitor, 3000, props);  // check again in 3 sec
                        break
                    // @ts-ignore: fall through
                    case 200:
                        if (jqXHR.status === 200) {  // done, ready to download
                            console.debug("download/" + props.uuid + "?token=" + data?.data['token'])
                            setUrl(new URL("download/" + props.uuid + "?token=" + data?.data['token'] + "&preview", APIHost).toString());
                            setState(ViewState.success);
                        }
                        break;
                    case 204:
                    case 206:
                    default:
                        break;
                }
            }
        ).fail(
            (jqXHR) => {
                setState(ViewState.failed);
            }
        ).always(
            checkForNotifications
        )
    }

    const body = () => {
        switch (state) {
            case ViewState.success:
                return <iframe src={url}
                               height={'100%!important'}
                               style={{width: '21cm'}}/>

            case ViewState.loading:
                return <Spin spinning style={{height: '80hv', width: '21cm'}}/>

            case ViewState.failed:
            default:
                return <p>Failed</p>
        }
    }

    return body()
}